import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import PremiumPopup from "./PremiumPopup";

const authConfig = require('../auth_config.json');

const createUser = (user) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('/create-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    given_name: user?.given_name,
                    family_name: user?.family_name,
                    nickname: user?.nickname,
                    picture: user?.picture,
                    locale: user?.locale,
                    updated_at: user?.updated_at,
                    email_verified: user?.email_verified,
                }),
            });

            if (response.status === 500) {
                throw new Error('Server error: Failed to create user');
            }

            const data = await response.json();
            const { message, user: createdUser } = data;
            resolve(createdUser);
        } catch (error) {
            console.error('Error creating user:', error);
            reject({
                message: 'Failed to create user',
                error,
            });
        }
    });
};

const updateUserToPremium = async (user) => {
    try {
        const response = await fetch('/update-user-to-premium', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                auth0Id: user?.sub,
                premiumStatus: 1,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update user to premium');
        }

        const data = await response.text();
        console.log('User updated to premium:', data);

        window.location.href = '/';
    } catch (error) {
        console.error('Error updating user to premium:', error);
        alert('Ha fallat l\'actualització a usuari premium. Si us plau, recarrega i intenta-ho de nou o contacta amb l\'Andreu (@HuguetAndreu) a Twitter.');
    }
}

async function handleCheckout(userData, getAccessTokenSilently) {
    try {
      // Retrieve the access token from Auth0
    //   const accessToken = await getAccessTokenSilently({
    //     audience: authConfig.audience, // Make sure this matches the audience in your Auth0 API configuration
    //   });
  
      // Call your backend to create a checkout session
      const response = await fetch('/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include the access token in the Authorization header
        //   'Authorization': `Bearer ${accessToken}`,
        },
        // You can pass any other required parameters in the body
        body: JSON.stringify({
            userId: userData?.userId,
        }),
      });
  
      // ... the rest of your handleCheckout function

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      // Handle errors here
      console.error('Error during checkout:', error);
    }
  }

function PremiumUserProfile({ user }) {
    return (
        <img
            src={`/assets/premium_avatar.png`}
            alt={user?.name}
            style={{ borderRadius: '50%', width: '40px', height: '40px' }}
        />
    );
}

function PremiumFeatures({
    userData,
    setUserData,
    isPremium,
    setIsPremium,
    showPremiumPopup,
    setShowPremiumPopup,
}) {
    const { loginWithRedirect, logout, isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
    const [userPicture, setUserPicture] = useState(null);

    const userPictureInitials =
        user?.name ? 'https://avatar.iran.liara.run/username?username=' + encodeURIComponent(user.name) :
        'https://avatar.iran.liara.run/public/38';

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         fetch(`/user-profile-picture`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 sub: user?.sub
    //             }),
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 setUserPicture(data?.picture);
    //             });
    //     }
    // }, [isAuthenticated, user]);

    useEffect(() => {
        if (userData !== null) {
            const fetchedIsPremium = parseInt(userData?.premiumStatus) === 1;
            setIsPremium(fetchedIsPremium);
        }
    }, [userData]);

    useEffect(() => {
        if (window.location.pathname === '/success') {
            const queryParams = new URLSearchParams(window.location.search);
            const userId = queryParams.get('user_id');
            if (userId) {
                updateUserToPremium({
                    sub: userId
                });
            }
        } else if (window.location.pathname === '/showPopup') {
            if (!isPremium) setShowPremiumPopup(true);
            else {
                setShowPremiumPopup(false);
                window.history.pushState({}, '', '/');
            }
        }
    }, [
        window.location.pathname,
        setShowPremiumPopup,
        isPremium,
    ]);

    useEffect(() => {
        if (isAuthenticated) {
            createUser(user)
                .then(setUserData)
                .then(() => {
                    document.cookie = `userId=${user.sub}; path=/`;
                });
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        };

        const userId = getCookie('userId');
        if (userId) {
            fetch(`/get-user-data/${userId}`)
                .then(response => response.json())
                .then(data => {
                    setUserData(data);
                    setIsPremium(parseInt(data.premiumStatus) === 1);
                })
                .catch(error => console.error('Error fetching user data:', error));
        }
    }, []);

    return (!isLoading && (!isAuthenticated || userData !== null)) ? (
        <div>
            { showPremiumPopup && <PremiumPopup isPremium={isPremium} isAuthenticated={isAuthenticated} redirectToStripe={() => handleCheckout(userData, getAccessTokenSilently)} loginWithRedirect={loginWithRedirect} setShowPremiumPopup={setShowPremiumPopup} /> }

            { !isAuthenticated ?
                // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                //     <button style={{ paddingLeft: '1rem', paddingRight: '1rem' }} className={isPremium ? 'premium' : ''} onClick={loginWithRedirect}>
                //         Inicia sessió per accedir a la versió professional (en proves!)
                //     </button>
                // </div>
                <></>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        <PremiumUserProfile user={user} />
                        <p>{isAuthenticated ? `${user.name}` : null}</p>
                    </div>

                    <div>
                        { !isPremium && <button className={'premium'} onClick={() => setShowPremiumPopup(true)}>✨ Versió professional (24,99€)</button>}
                        { isPremium && <p>VERSIÓ PROFESSIONAL</p>}
                    </div>

                    <button className={isPremium ? 'premium' : ''} onClick={() => {
                        logout();
                        document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    }}>Tanca sessió</button>
                </div>
            }
        </div>
    ) : (
        <div style={{
            height: '40px',
        }} />
    )
}

export default PremiumFeatures;