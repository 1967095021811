import { useEffect, useState, useRef } from "react";
import StateOutput from "./output/StateOutput";
import '../styles/output.css'
import FullAudio from "./output/FullAudio";
import TranscripcioOutput from "./output/TranscripcioOutput";
import AudioParts from "./output/AudioParts";

function Output({ fileName, state, setState, processor, isModelLoaded, isAudioLoaded, numSpeakers, isPremium }) {
    const [lines, setLines] = useState([]);
    const [audioParts, setAudioParts] = useState([]);
    const [fullAudio, setFullAudio] = useState(null);
    const [queue, setQueue] = useState([]);
    const [toBeFinished, setToBeFinished] = useState(false);
    const [speakers, setSpeakers] = useState([]);
    const audioRef = useRef(null); // Add this line

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (queue.length > 0) {
                setLines(prev => [...prev, queue[0]]);
                setQueue(prev => prev.slice(1));
            }
        }, 75);

        return () => clearInterval(intervalId);
    }, [queue]);

    useEffect(() => {
        if (toBeFinished && queue.length === 0) {
            setState(prev => Math.max(prev, 7));
        }
    }, [toBeFinished, queue.length]);

    const addNewLine = (line) => {
        // Add to queue
        setQueue(prev => [...prev, line]);
    }

    const addNewAudioPart = (audioPart) => {
        setAudioParts(prev => [...prev, audioPart]);
    }

    const addFullAudio = (audio) => {
        setFullAudio(audio);
    }

    const changeSpeakers = (speakers) => {
        setSpeakers(speakers);
    }

    const changeState = (newState) => {
        if (newState === 7) {
            setToBeFinished(true);
        } else {
            setState(prev => Math.max(prev, newState));
        }
    }

    useEffect(() => {
        processor?.setOutput({
            lines: addNewLine,
            audioParts: addNewAudioPart,
            fullAudio: addFullAudio,
            speakers: changeSpeakers,
            changeState,
        });
    }, [processor]);

    useEffect(() => {
        if (!isModelLoaded && !isAudioLoaded) {
            setState(0);
        }

        if (isModelLoaded && !isAudioLoaded) {
            setState(1);
        }

        if (!isModelLoaded && isAudioLoaded) {
            setState(2);
        }

        if (isModelLoaded && isAudioLoaded) {
            setState(3);
        }
    }, [
        isModelLoaded,
        isAudioLoaded,
    ]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (state > 3 && state < 7) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [state]);

    return (
        <div>
            <StateOutput state={state} />
            <FullAudio fullAudio={fullAudio} ref={audioRef} />
            {/* <AudioParts processor={processor} audioParts={audioParts} state={state} /> */}

            <TranscripcioOutput fileName={fileName} lines={lines} state={state} speakers={speakers} numSpeakers={numSpeakers} isPremium={isPremium} audioRef={audioRef} />
        </div>
    );
}

export default Output;